import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, mergeMap, retryWhen, switchMap } from 'rxjs/operators';

import { AlertService } from '@core/services/ui/alert.service';
import { AuthActions } from '@core/store/auth/auth.actions';
import { AuthState } from '@core/store/auth/auth.state';
import { Store } from '@ngxs/store';
import { AuthLoginComponent } from '@shared/components/templates';
import { isMobile } from '@shared/utils';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';

export const maxRetries = 3;
export const delayMs = 3000;

@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {
  private readonly dialogs = inject(TuiDialogService);
  private readonly alerts = inject(AlertService);
  constructor(private store: Store) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen((errors) =>
        errors.pipe(
          mergeMap((error, index) => {
            if (index < maxRetries && error.status === 401) {
              return this.store.dispatch(new AuthActions.RefreshToken()).pipe(
                switchMap(() => {
                  const newToken = this.store.selectSnapshot(AuthState.token);
                  if (newToken) {
                    const updatedRequest = request.clone({
                      setHeaders: { Authorization: `Bearer ${newToken}` },
                    });
                    return next.handle(updatedRequest);
                  }
                  throw error;
                }),
                catchError(() => throwError(error))
              );
            }
            throw error;
          }),
          delay(delayMs)
        )
      ),
      catchError((error: HttpErrorResponse) => {
        if (error) {
          if (error.error instanceof ErrorEvent) {
            // Error en el lado del cliente
          } else {
            // Error en el lado del servidor
            this.handleHttpError(error);
          }
        }
        return throwError(error);
      })
    );
  }


  handleHttpError(err: HttpErrorResponse) {
    switch (err.status) {
      case 401:
        this.store.dispatch(new AuthActions.Logout());
        this.dialogs
          .open(new PolymorpheusComponent(AuthLoginComponent), {
            closeable: true,
            size: isMobile() ? 'fullscreen' : 'm',
            dismissible: true,
            required: true,
          })
          .subscribe();
        // todo - user unauthorized --> redirect to login
        break;
      case 403:
        // this.alerts.error('No tienes permiso para realizar esta operación.');

        break;

      case 429:
        this.alerts.error(
          'Haz sobrepasado el límite de solicitudes. Inténtalo de nuevo en unos minutos.'
        );
        break;
      case 409:
        const message = err.error.message;
        if (!message) break;

        this.alerts.warning(message, err.error.title);

        break;

      case 500:
        /// todo implementar logging, trace y alerta
        break;
    }
  }
}
