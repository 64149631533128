<section class="w-full p-5">
  <p class="text-center text-3xl font-bold">Compra de créditos</p>

  <div class="mt-8">
    <img src="assets/images/default/form1.svg" class="mx-auto w-20" />
  </div>

  <p class="mt-8 text-center text-base">
    Elige el número de créditos necesarios para conectar con el talento de tu interés.
  </p>

  <app-buy-credits
    (onActivateFreeTrial)="onBuyCreditChange(null)"
    (onBuyCredits)="onBuyCreditChange($event)"
    [isFreeTrialExchanged]="isFreeTrialExchanged"
    buttonColor="primary" />
</section>
