import { inject, Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Publication, PublicationAllRelations, PublicationsApi } from '@tribuu-api';
import { tap } from 'rxjs';
import { PublicationActions } from './publication.actions';

export type PublicationRelations = Pick<
  PublicationAllRelations,
  | 'triber'
  | 'category'
  | 'certifications'
  | 'specialities'
  | 'languages'
  | 'paymentModel'
  | 'school'
>;

export type PublicationItemState = Publication & Partial<PublicationRelations>;
export interface PublicationsStateModel {
  publications: { [key: string]: PublicationItemState };
  loadedProfiles: string[];
  loading: boolean;
  loaded: boolean;
}

@State<PublicationsStateModel>({
  name: 'publications',
  defaults: {
    publications: {},
    loadedProfiles: [],
    loading: false,
    loaded: false,
  },
})
@Injectable()
export class PublicationsState implements NgxsOnInit {
  private publicationsAPI = inject(PublicationsApi);
  @Selector()
  static publications(state: PublicationsStateModel): Array<Publication | PublicationAllRelations> {
    return Object.values(state.publications);
  }

  @Selector()
  static publicationBySlug(state: PublicationsStateModel) {
    return (slug: string) => {
      return state.publications[slug];
    };
  }

  @Selector()
  static publicationsTribers(state: PublicationsStateModel) {
    return (triberId: string) => {
      return Object.values(state.publications).filter((element) => element.triberId === triberId);
    };
  }
  @Selector()
  static publicationsLoading(state: PublicationsStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: PublicationsStateModel) {
    return state.loaded;
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    ctx.dispatch(new PublicationActions.FetchAll());
  }

  /**
   * Elimina una publicación del estado.
   * @param {StateContext<PublicationsStateModel>} ctx - Contexto del estado.
   * @param {PublicationActions.Delete} action - Acción de publicación para eliminar.
   */
  @Action(PublicationActions.Delete)
  removePublication(ctx: StateContext<PublicationsStateModel>, action: PublicationActions.Delete) {
    const state = ctx.getState();
    const updatedPublications = Object.values(state.publications).filter(
      (j) => j.uid !== action.uid
    );
    ctx.patchState({
      publications: this.map(updatedPublications),
    });
  }

  @Action(PublicationActions.Fetch)
  fetchPublication(ctx: StateContext<PublicationsStateModel>, { slug }: PublicationActions.Fetch) {
    const state = ctx.getState();
    const loaded = ctx.getState().loaded;
    if (state.publications[slug] && loaded) return;
    return this.publicationsAPI.findBySlug({ slug }).pipe(
      tap((publication) => {
        return ctx.patchState({
          publications: { ...state.publications, [publication.slug]: publication },
        });
      })
    );
  }

  // fetchtriber
  @Action(PublicationActions.FetchFromTriber)
  fetchPublicationByUid(
    ctx: StateContext<PublicationsStateModel>,
    { triberId }: PublicationActions.FetchFromTriber
  ) {
    const state = ctx.getState();
    if (state.loadedProfiles.includes(triberId)) return;

    return this.publicationsAPI
      .findPublicationAll({
        limit: 20,
        triberUID: triberId,
      })
      .pipe(
        tap((publicationResponse) => {
          const publicationsUidFromResponse = publicationResponse.items.map((item) => item.uid);
          const publicationStateWithoutResponse = Object.values(state.publications).filter(
            (item) => !publicationsUidFromResponse.includes(item.uid)
          );
          ctx.patchState({
            loadedProfiles: [...state.loadedProfiles, triberId],
            publications: this.map([
              ...publicationStateWithoutResponse,
              ...publicationResponse.items,
            ]),
          });
        })
      );
  }

  /**
   * Obtiene todas las publicaciones y las almacena en el estado.
   * @param {StateContext<PublicationsStateModel>} ctx - Contexto del estado.
   * @returns {Observable<any>} - Observable que devuelve las publicaciones obtenidas.
   */
  @Action(PublicationActions.FetchAll)
  fetchAllPublications(ctx: StateContext<PublicationsStateModel>) {
    return this.publicationsAPI
      .findPublicationAll({
        limit: 50,
      })
      .pipe(
        tap((publications) => {
          const publicationsItems = publications.items;
          ctx.patchState({
            publications: this.map(publicationsItems),
            loading: true,
            loaded: true,
          });
        })
      );
  }

  private map(publications: PublicationItemState[]) {
    return publications.reduce((acc, item) => ({ ...acc, ...{ [item.slug]: item } }), {});
  }
}
