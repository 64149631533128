<div class="grid grid-cols-1 divide-y overflow-y-auto" *rxLet="triber$; let triber">
  <div
    class="flex cursor-pointer items-center gap-2 bg-white px-3 py-2 hover:bg-slate-50 hover:text-indigo-800"
    (click)="goto(ROUTES.APP_ACCOUNT_DEFAULT)">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="h-5 w-5 text-gray-600">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    </svg>

    <div class="text-xs md:text-sm">Mi Cuenta</div>
  </div>

  <!-- <div
    class="flex cursor-pointer items-center gap-2 bg-white px-3 py-2 hover:bg-slate-50 hover:text-indigo-800"
    (click)="goto(ROUTES.APP_ACCOUNT_CREDITS)">
    <img src="assets/icons/mis-creditos.png" alt="icono de mis creditos tribuu" >
    <div class="text-xs md:text-sm">Créditos Tribuu</div>
  </div> -->

  <div
    *ngIf="!!triber"
    class="flex cursor-pointer items-center gap-2 bg-white px-3 py-2 hover:bg-slate-50 hover:text-indigo-800"
    (click)="goto(ROUTES.APP_ACCOUNT_PROFILE)">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="h-5 w-5 text-gray-600">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
    </svg>
    <div class="text-xs md:text-sm">Perfil Triber</div>
  </div>
  <div
    class="flex cursor-pointer items-center gap-2 bg-white px-3 py-2 hover:bg-slate-50 hover:text-indigo-800"
    (click)="goto(ROUTES.APP_ACCOUNT_VACANCIES_ME)">
    <svg-icon svgClass="h-5 w-5 text-gray-600" src="assets/icons/mis-vacantes.svg"></svg-icon>
    <div class="text-xs md:text-sm">Mis Vacantes</div>
  </div>

  <a
    href="https://academy.tribuu.com"
    target="_blank"
    class="flex cursor-pointer items-center gap-2 bg-white px-3 py-2 hover:bg-slate-50 hover:text-indigo-800">
    <img src="assets/images/icons/menu/academy.svg" alt="academy icon" class="h-5 w-5" />
    Academy
  </a>

  <!-- <div
    class="flex items-center gap-2 bg-white px-3 py-2 hover:bg-slate-50 hover:text-indigo-800"
    (click)="goto(ROUTES.APP_ACCOUNT_SESSIONS)">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="h-4 w-4 text-gray-500">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
    </svg>

    <span class="text-sm">Mis compras</span>
  </div> -->
  <!-- <div class="flex items-center gap-2 bg-white px-3 py-2 hover:bg-slate-50 hover:text-indigo-800">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="h-4 w-4 text-gray-500">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
    </svg>

    <span class="text-sm">Mensajes</span>
  </div> -->

  <!-- <div class="flex items-center gap-2 bg-white px-3 py-2 hover:bg-slate-50 hover:text-indigo-800">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="h-4 w-4 text-gray-500">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
    </svg>
    <span class="text-sm">Configuración</span>
  </div> -->
  <!-- <span class="bg-white py-2 px-3 text-left hover:bg-slate-50 hover:text-indigo-800">
    Mi cuenta
  </span> -->

  <span
    class="block cursor-pointer bg-white px-3 py-2 text-center text-xs text-red-500 md:text-sm"
    (click)="logout()">
    Cerrar sesión
  </span>
</div>
